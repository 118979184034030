@import "variables";
@import "mixins";

button {
  outline: none;

  .mat-button-focus-overlay {
    display: none !important;
  }

  &.mat-icon-button {
    &[data-notifications] {
      position: relative;

      &:before {
        content: attr(data-notifications);
        position: absolute;
        bottom: 0px;
        right: 0px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 18px;
        padding: 0 5px;
        font-weight: bold;
        background: color(warning);
        color: color(base-0);
        font-size: 14px;
        border-radius: 2px;
        z-index: 2;
      }
    }

    &[disabled] {
      opacity: .7;
    }

    .tip {
      background: #FFD741;
      border-radius: 2px;
      padding: 0 6px;
      font-size: 14px;
    }
  }
}

button[mat-flat-button] {
  box-shadow: none;
  padding: 0 15px;
  border-radius: 20px;
  border: 1px solid #009ADE;
  font-weight: 600;
  color: color(base-1);
  background: #009ADE;
  text-transform: uppercase;
  font-size: 16px;
  min-width: 148px;
  height: 34px;
  line-height: normal;

  &.email-btn {
    background: white;
    border: 1px solid #009ADE;
    color: #009ADE;
  }

  &.send-btn {
    background: #009ADE;
    color: white;
    border: none;
  }

  &.normal-btn {
    background: white;
    color: color(base-6);
    border: 1px solid color(base-6);
  }

  .mat-button-wrapper {
    display: inline-flex;
  }

  i {
    margin-left: 10px;

    &:before {
      font-size: 17px;
    }
  }
}

