@import '~angular-calendar/css/angular-calendar.css';
@import "variables";
@import "mixins";
@import "fonts";
@import "icons";
@import "forms";
@import "dialog";
@import "toolbar";
@import "buttons";
@import "calendar";
@import "menu";
@import "elevation";

///* width */
//::-webkit-scrollbar {
// width: 8px;
//}
//
///* Track */
//::-webkit-scrollbar-track {
// box-shadow: inset 0 0 1px grey;
// border-radius: 10px;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
// background: #d4d4d4;
// border-radius: 10px;
//}
//
///* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
// background: #b30000;
//}

.mat-badge-content {
  color: #35373D;
  background: #FFD741;
}

html,
body {
  width: 100%;
  height: 100%;
  //min-width: 1345px;
}

hr {
  &.line-hr {
    border-top: 1px solid #d8d8d8;

    &.dark {
      border-top: 1px solid #213d4d;
    }
  }
}

th {
  user-select: none;
}

web-feedback-answer,
web-feedback-text-answer,
web-feedback-choice-answer,
web-feedback-yesno-answer,
web-feedback-rating-answer {
  .mat-list {
    &.customer-feedback {
      .mat-subheader {
        font-size: 16px;
        color: #000;
      }

      .mat-list-item {
        label {
          color: rgba(0, 0, 0, 0.5);
        }

        textarea {
          border: 1px solid #d2d2d2;
          width: 100%;
          height: 60px;
          padding: 0px 10px;
        }
      }
    }
  }
}

body {
  margin: 0;
  background-color: #fafafa; // matches theme
}

mat-sidenav-container {
  &.public {
    background-color: #fff !important;
  }
}

* {
  box-sizing: border-box;
}

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color !important;
  }

  .background-color-#{$name} {
    background-color: $color !important;
  }

  .border-color-#{$name} {
    border-color: $color !important;
  }
}

*[readonly],
.readonly {
  cursor: not-allowed;
  pointer-events: none;
}

*[disabled],
.disabled {
  opacity: .5;
  cursor: not-allowed !important;
  pointer-events: none;
}

.gotham-book {
  font-family: 'Gotham-Book';
}

.display-flex {
  display: flex !important;
}

.align-items-center {
  align-items: center !important;
}

.no-overflow {
  mat-dialog-container {
    overflow: hidden;
  }
}

.mat-menu-panel {
  &.product-picker-menu {
    max-height: 200px !important;
    max-width: 500px !important;
  }
}

.mat-icon-button {
  &.edit {
    color: #003B71
  }
}

mat-sidenav-content {
  overflow: hidden !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #003B71;
  stroke-width: 5% !important;
}

.email-detail-wrapper {
  margin: 20px;
}

.mat-calendar-body-selected {
  background-color: #7DCBEC !important;
  color: #fff;
}

[hidden-but-there] {
  opacity: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  ;
  border: 0 !important;
}

.cdyNRy {
  a {
    &.blockbuilder-branding {
      display: none !important;
    }
  }
}

div {
  &.disabled-overlay {
    width: 100%;
    height: 100%;
    background-color: #d2d2d2;
    opacity: 0.5;
    position: absolute;
  }
}

.ng-touched.ng-invalid {
  border-color: red !important;

  &.mat-checkbox {
    color: red;
  }
}

button {
  &.back-btn {
    margin-left: 16px;
    pointer-events: all;
    color: color(base-6);
    font-weight: 600 !important;

    i {
      margin-right: 8px;
    }
  }
}

.d-flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.mt-10 {
  margin-top: 10px;
}

.mat-ink-bar {
  background-color: #003b71 !important;
}

.mat-tab-header-pagination-after {
  margin-right: 28px !important;
}

div.centeredFlexDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
div.dwsLittlePadding{
  padding: 1rem;
}

div.dwsMediumPadding{
  padding: 2rem;
}

div.dwsBigPadding{
  padding: 3rem;
}

.input-wrapper,
.field-wrapper {

  // style for input elements
  input,
  textarea,
  mat-select {
    font-family: 'Mulish', 'Arial' !important;
    line-height: 1.4rem;
    font-size: 0.9rem;
    border: 1px solid #D2D2D2;
    box-shadow: 0 1px 1px 0 rgba(152, 152, 152, 0.22);
    border-radius: 10px;
  }

  mat-select {
    font-weight: 600;
  }

  ::ng-deep {

    input,
    textarea,
    mat-select {
      font-family: 'Mulish', 'Arial' !important;
      line-height: 1.4rem;
      font-size: 0.9rem;
      border: 1px solid #D2D2D2;
      box-shadow: 0 1px 1px 0 rgba(152, 152, 152, 0.22);
      border-radius: 10px;
    }

    mat-select {
      font-weight: 600;
    }

  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 215, 64, 0.0);
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #003B71;
  border-radius: 100px;
}

/* COLOR PICKER */
.color-picker {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  border: 1px solid #D2D2D2 !important;
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12) !important;
  cursor: default;
  height: auto;
  position: absolute;
  user-select: none;
  width: 230px;
  z-index: 1000;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  & .arrow {
    display: none;
  }
}

.dws-button {
  box-shadow: none;
  padding: 0 15px;
  border-radius: 20px;
  border: 1px solid #009ADE;
  font-weight: 600;
  color: #FFFFFF;
  background: #009ADE;
  text-transform: uppercase;
  font-size: 16px;
  min-width: 148px;
  height: 34px;
  line-height: normal;
}

.dws-toolbar-spacer{
  flex: 1 1 auto;
}

.cke_notification_warning {
  display: none !important;
}

.cal-event-container {
  overflow: hidden;
}

.flat {
    box-shadow: none;
    border: 1px solid #D2D2D2;
    border-radius: 10px;

  mat-card {
    background-color: #fff;
  }
}