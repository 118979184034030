$font-family-icomoon: 'Divinea Icons' !important;
$icomoon-font-path: "../icons/" !default;

@each $key, $value in $icons {
  .icon-#{$key} {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: $value;
      font-family: $font-family-icomoon;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: 24px;
    }
  }
}

@font-face {
  font-family: 'Divinea Icons';
  src: url('#{$icomoon-font-path}/icomoon.ttf?rydnse') format('truetype'),
  url('#{$icomoon-font-path}/icomoon.woff?rydnse') format('woff'),
  url('#{$icomoon-font-path}/icomoon.svg?rydnse#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

i[class^="icon-circled"] {
  position: relative;

  &:after {
    transform: translateY(-50%) translateX(-50%);
    content: "";
    left: 50%;
    top: 50%;
    position: absolute;
    width: 90%;
    height: 90%;
    z-index: -1;
    background: #FFF;
    border-radius: 50%;
    overflow: hidden;
    display: block;
  }
}
