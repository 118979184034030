@import "variables";
@import "mixins";

.mat-toolbar {
  min-height: 45px;
  position: relative;

  .main-toolbar {
    background: color(base-6);
    justify-content: space-between;
    height: 45px;
    position: fixed;
    z-index: 500;

    .menu-wrapper {
      position: relative;
      z-index: 1;
      display: flex;
      align-items: center;
    }

    .logo-wrapper {
      width: 100%;
      position: absolute;
      left: 50px;

      a {
        width: 160px;
        display: block;
        margin: 0 auto;

        img {
          width: 100px;
          display: block;
        }
      }
    }

    .version {
      padding-top: 4px;
      margin-left: 225px;
    }

    .actions-wrapper {
      min-width: 270px;
      display: flex;
      justify-content: space-between;

      button {
        font-size: 19px;
        font-weight: 500;
      }
    }
  }

  .minor-toolbar-row {
    background: #fa3a64;
    justify-content: space-between;
    height: 30px;
    position: fixed;
    z-index: 500;
    padding: 0;

    .menu-wrapper {
      position: relative;
      z-index: 1;
    }

    .logo-wrapper {
      width: 100%;
      position: absolute;
      left: 20px;

      a {
        width: 160px;
        display: block;
        margin: 0 auto;

        img {
          width: 100px;
          display: block;
        }
      }
    }

    .version {
      padding-top: 4px;
      margin-left: 50px;
    }

    .actions-wrapper {
      min-width: 270px;
      display: flex;
      justify-content: space-between;

      button {
        font-size: 19px;
        font-weight: 500;
      }
    }
  }

  .calendar-toolbar {
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    background: color(base-1);
    height: 63px;
    box-shadow: 0 1px 30px 3px rgba(44, 44, 44, 0.06);

    .actions {
      width: 450px;
      display: flex;
      justify-content: space-between;

      button[mat-raised-button] {
        box-shadow: none;
        padding: 0 35px;
        border-radius: 20px;
        border: 1px solid #003B71;
        font-weight: 500;
        font-size: 16px;
        height: 34px;
        line-height: normal;
        color: #003B71;
      }

      button[mat-icon-button] {
        width: 34px;
        height: 34px;
        box-shadow: none;
        padding: 0;
        border-radius: 7px;
        border: 1px solid #003B71;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        line-height: normal;
        vertical-align: top;
        color: #003B71;
      }

      .mode-wrapper {
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .filters {
      align-items: center;
      display: flex;
      font-size: 0;

      .mat-button-toggle-group {
        height: 28px;
        border-color: #009ADE;
        border-radius: 20px;
        color: #009ADE;

        .mat-button-toggle {
          font-size: 0;
          border-color: #009ADE;

          &.mat-button-toggle-checked {
            background: #009ADE;

            .mat-button-toggle-label-content {
              color: #fff;
            }
          }

          .mat-button-toggle-button {
            display: inline-flex;
            align-items: center;
            height: 100%;
          }

          .mat-button-toggle-label-content {
            padding: 0;
            font-size: 14px;
            font-weight: 500;
            line-height: 30px;
            padding: 1px 10px 0;
            line-height: normal;
            min-width: 96px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #009ADE
          }
        }
      }
    }
  }
}

.notification-menu {
  //transform: translateX(calc(-50% + 27px )) translateY(20px) !important;
  border-radius: 10px !important;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.11);
  max-width: 350px !important;

  header {
    padding: 10px 20px;
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: space-between;
    background: #F7F7F7;

    h2 {
      color: color(base-6);
      font-size: 16px;
      font-weight: 500;
      margin: 0px 0px 0px;
    }

    .dismissAllLink {
      color: #0099db;
      text-decoration: underline;
      font-size: smaller;
      cursor: pointer;
      margin: 0 15px;
    }
  }

  .loadMoreLink {
    color: #0099db;
    text-decoration: underline;
    font-size: medium;
    cursor: pointer;
    margin: 0 15px;
  }

  button {

    .dot {
      height: 7px;
      width: 7px;
      background-color: #0099db;
      border-radius: 50%;
      display: inline-block;
    }

    .notification-message{
      font-size: 14px;
      padding: 5px 0;
      color: black;
    }

    p {
      margin: 0px;

      &.notification-txt {
        font-size: 12px;
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
      }

      &.date-txt {
        font-size: 12px;
        margin: 4px 0px;
      }

      &.time-txt {
        font-size: 10px;
        margin-top: 3px;
        float: right;

        i {
          &:before {
            font-size: 12px;
          }
        }
      }

      &.more {
        text-align: center;
        padding: 10px;
      }
    }
  }

  .mat-menu-content:not(:empty) {
    padding: 0;

    .mat-menu-item {
      display: flex;
      white-space: normal;
      height: auto;
      padding: 5px;
      /* margin: 5px 0; */
      line-height: 1;
      border-bottom: 1px solid #e0e0e0;
      align-content: stretch;
      align-items: center;
      
      .leftBarNotification {
        width: 5%;
      }

      .rightBarNotification {
        width: 95%;

        .notification-single-icon {
          font-size: 20px;
          margin-right: 5px;
        }

      }

      strong {
        display: block;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        height: 100%;
        min-height: 35px;
        font-weight: 500;
        text-wrap: wrap;
        i {
          &:before {
            font-size: 18px;
          }
        }
      }

      span {
        display: flex;
        align-items: center;
        line-height: normal;
        font-size: 12px;
        font-weight: normal;

        i {
          margin-right: 5px;

          &:before {
            font-size: 15px;
          }
        }
      }

    }
  }
}

.account-menu {
  width: 270px;
  border-radius: 10px !important;
  box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.11);

  .mat-menu-content:not(:empty) {
    padding: 30px 15px;
    text-align: center;

    .profile {
      display: flex;
      flex-direction: column;

      .avatar {
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      strong {
        display: block;
        font-size: 16px;
      }

      span {
        display: block;
        font-size: 12px;
        color: color(base-9);
      }
    }

    .actions {
      display: flex;
      flex-direction: column;

      button {
        margin: 20px auto 0;
        text-align: center;
        text-transform: uppercase;
        max-width: max-content;
        border-color: color(base-7);
        box-shadow: 0 1px 1px 0 rgba(152, 152, 152, 0.22);
        border-radius: 20px;
      }
    }

  }
}