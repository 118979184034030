#menu {
  width: 245px;

  .btn-close {
    position: absolute;
    right: 5px;
    top: 5px;

    i:before {
      font-size: 26px;
    }
  }

  .menu-wrapper {
    padding: 50px 0;

    ul {
      margin: 0;
      padding: 0 26px;
      list-style: none;

      li {
        width: 100%;
        max-width: 193px;
        display: flex;
        align-items: center;
        height: 63px;
        justify-content: center;
        background: #F4F6F8;
        margin: 0 auto 10px;
        border-radius: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          text-decoration: none;
          color: #003B71;
          font-weight: 500;
          width: 100%;
          height: 100%;
          border: none;
          padding: 0 23px;
          background: none;
          cursor: pointer;

          i {
            color: #003B71;
            margin-right: 11px;
            min-width: 48px;

            &:before {
              font-size: 34px;
            }

            &.icon-toast {
              &:before {
                font-size: 48px;
              }
            }

            &.icon-basket {
              &:before {
                font-size: 38px;
              }
            }

            &.icon-bottle {
              &:before {
                font-size: 40px;
              }
            }
          }

          strong {
            display: inline-block;
            width: 130px;
            text-align: left;
            font-size: 11px;
            line-height: 17px;
            font-weight: 500;
            text-transform: uppercase;
            font-family: 'Poppins', sans-serif;
            padding-top: 5px;
          }
        }

        &.sub-button {
          height: 43px;
          i {
            &:before {
              font-size: 20px !important;
            }
          }
          i, strong {
            color: #33638E;
          }
        }
      }
    }
  }
}
