@import "variables";
@import "mixins";

.calendar-wrapper {
  padding: 0;
  background: #fff;
  position: relative;

  .date-header {
    height: 60px;
    width: 100%;

    .date-period {
      padding: 10px 00px 0px 20px;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 100px 200px 80px 100px;

      .mat-button-wrapper {
        i:before {
          font-size: 16px;
          color: color(base-7);
        }
      }

      p {

        &.date-current-year,
        &.selected-date {
          margin: 0px;
          font-weight: bold;
          line-height: 1;
        }

        &.selected-date {
          margin-top: 3px;
          align-self: center;
          font-size: 16px;
        }

        &.date-current-year {
          font-size: 26px;
          align-self: center;
        }
      }
    }
  }

  .cal-week-view {
    border-top: none;

    .cal-all-day-events {
      background: white;
      //padding-left: 40px;
      z-index: 1;

      .cal-day-columns {
        .cal-time-label-column {
          width: 90px;
        }
        padding-right: 10px;
      }
    }
  }

  .cal-month-view {
    font-family: "Poppins", sans-serif;

    .cal-open-day-events {
      background-color: #f7f7f7;
      color: #003B71;
      line-height: 28px;
      box-shadow: none;
      padding-right: 44px;

      mwl-calendar-event-title {
        font-weight: bold;
      }
    }

    .cal-event {
      background-color: #009ade !important;
      width: 6px;
      height: 6px;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    .cal-header {
      .cal-cell {
        /*background: rgba(23, 61, 79, 0.1);*/
        background: #e9ecee;
        border: none;
        text-align: left;
        padding: 0 15px;
        height: 40px;
        line-height: 2.6;
        outline: none;
        text-transform: uppercase;
        word-spacing: 15px;
        font-weight: normal;
        font-family: "Poppins", sans-serif;
      }
    }
  }

  .cal-day-headers {
    border: none;


    .cal-header {
      background: #e7ebed;
      border: none;
      text-align: left;
      padding: 0 15px;
      height: 40px;
      display: flex;
      align-items: center;
      outline: none;
      text-transform: uppercase;
      word-spacing: 15px;
      font-family: "Poppins", sans-serif;

      &:hover {
        background: #e7ebed;
      }

      &:first-child {
        border-left: none;
        /* padding-left: 25px; */
      }

      &.cal-today {
        span {
          color: #009ade !important;
          font-weight: 700;
        }
      }

      span {
        opacity: 1;
        color: color(base-6) !important;
      }

      strong {
        font-family: "Poppins";
        font-weight: normal;
        color: color(base-6);
      }
    }
  }

  .cal-week-view .cal-day-headers {
    background: #e7ebed;
  }

  .cal-time-events {
    //padding-top: 20px;

    .cal-time-label-column {
      width: 90px;

      .cal-hour {
        .cal-time {
          width: 100%;
        }
      }
    }

    .cal-hour {
      .cal-hour-segment {
        border-bottom: none;

        /*  position: relative;
*/
        &:after {
          //width: 15px;
          height: 1px;
          content: "";
          position: absolute;
          top: 0px;
          right: 0;
          border-top: 1px solid #e7ebed;
        }

        .cal-time {
          font-weight: normal;
          padding-top: 0;
          font-family: "Poppins", sans-serif;
        }
      }
    }

    .cal-hour {
      background: none !important;

      .cal-hour-segment.cal-hour-start {
        border-top: 1px solid #e7ebed;
      }

      .cal-hour-segment.cal-after-hour-start {
        border-top: 1px dashed #e7ebed;
      }

      &:last-child {
        mwl-calendar-week-view-hour-segment {
          &:last-child {
            .cal-hour-segment {
              border-bottom: none;
            }
          }
        }
      }
    }

    .cal-day-columns {
      position: relative;

      .cal-day-column {
        /* position: static;
        border-top: 1px solid #eeeded; */


        .cal-current-time-marker {
          width: calc(100% + 15px);
          left: -15px;
          height: 1px;
          background: color(primary);

          &:before {
            width: 7px;
            height: 7px;
            content: "";
            background: color(primary);
            border-radius: 50%;
            left: 0;
            top: 0;
            display: block;
            transform: translateX(-50%) translateY(-50%);
          }
        }

        mwl-calendar-week-view-hour-segment:first-child {
          position: relative;

          /*&:before {
            content: "";
            height: 21px;
            top: -21px;
            width: 1px;
            position: absolute;
            left: -1px;
            border-left: 1px solid #eeeded;
          }*/
        }
      }
    }
  }

  .calendar-day-view {
    .cal-day-headers {
      position: absolute;
      top: 0;
      width: 100%;
    }

    .cal-events-container {
      width: calc(100% - 112px) !important;
      left: 112px !important;
    }

    .cal-time-events {
      border-right: none;

      .cal-day-columns {
        .cal-day-column {
          border: none;

          mwl-calendar-week-view-hour-segment {
            &:before {
              content: "";
              height: 0px;
              top: 0;
              width: 100%;
              position: absolute;
              left: 95px;
              border-top: 1px solid #eeeded;
            }
          }

          .cal-current-time-marker {
            z-index: 1;
            left: 93px;
          }
        }
      }

      .cal-hour {
        .cal-hour-segment {
          border: none;

          .cal-time {
            width: 70px;
            text-align: right;
            padding-right: 5px;
          }
        }
      }
    }
  }
}

.cal-week-view .cal-time-events .cal-event {
  border-radius: 5px !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  border: none;
  animation: bounceIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration:.1s; /* don't forget to set a duration! */

}

.cal-time-events {
  max-height: calc(100vh - 60px - 40px - 63px - 45px);
  overflow-y: scroll;
}

.cal-week-view .cal-day-headers {

  padding-left: 90px;
  padding-right: 10px;
}

.cal-week-view .cal-header {
  max-width: calc( ((100vw - 100px) / 7) - 0px );
}

.cal-week-view .cal-day-column {
  border-left: none;

  & .cal-hour {
    border-left: 2px solid #e7ebed;
  }
}

.cal-week-view .cal-day-headers .cal-header {
  border-right: none!important;
  border-left: 2px solid #e1e1e1;

  &:first-child {
    border-left-color: #e7ebed;
  }

}

.calendar-week-view .cal-week-view .cal-day-column{
  max-width: calc((100vw - 100px) / 7);
}

.calendar-day-view .cal-week-view .cal-day-column{
  max-width: 90%;
}

.cal-week-view .cal-all-day-events .cal-events-row{
  margin-left:90px;
  margin-right:10px;
}