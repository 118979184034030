@import '~@angular/material/theming';

$font-family: "Poppins, Lato, Helvetica, Arial";

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat-typography-config(
  $font-family: $font-family,
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500)
);

@include angular-material-typography($custom-typography);

// paleta cores

$colors: (
  primary: #BA324F,
  secondary: #FC3560,
  accent: #FFE73D,
  warn: #BE3A56,
  warning: #FFF000,
  error: #BE3A56,
  confirmed: #31C671,
  rejected: #D45267,
  revoked: #BA324F,
  canceled: #BA324F,
  waiting: #007AFF,
  base-0: #000000,
  base-1: #FFFFFF,
  base-2: #E3F1F9,
  base-3: #163C4E,
  base-4: #006DA6,
  base-5: #036FA2,
  base-6: #003B71,
  base-7: #D2D2D2,
  base-8: #E8ECEE,
  base-9: #595959,
);

/// Font size base to calculations
$font-size-base-conversion: 14;

// Font Icons
// --------------------------------------------------
// Icon code array

$icons: (
  archived_folder: "\e94a",
  arrow-bold-left: "\e900",
  arrow-bold-right: "\e901",
  arrow-down: "\e902",
  arrow-drop-down: "\e95c",
  arrow-drop-up: "\e95b",
  arrow-left: "\e903",
  arrow-right: "\e904",
  arrow-up: "\e905",
  automation: "\e938",
  baby: "\e906",
  back: "\e946",
  basket: "\e907",
  bottle-plus: "\e909",
  bottle: "\e908",
  bottles: "\e90a",
  burger: "\e934",
  calendar-scheduele: "\e90c",
  calendar: "\e90b",
  campaign: "\e939",
  cellar: "\e932",
  checkmark: "\e90d",
  circle-check: "\e96b",
  circle-uncheck: "\e96c",
  circled-checkmark: "\e90e",
  circled-close: "\e90f",
  circled-etc: "\e910",
  circled-minus: "\e911",
  circled-plus: "\e912",
  clock: "\e913",
  close: "\e914",
  coin: "\e960",
  contact: "\e93d",
  credit-card: "\e915",
  cross: "\e916",
  dashboard: "\e917",
  date-opened: "\e94e",
  diamond: "\e942",
  discount: "\e93c",
  divinea: "\e918",
  door: "\e956",
  download: "\e959",
  duplicar: "\e948",
  edit: "\e945",
  employee: "\e955",
  engine: "\e954",
  envelope: "\e941",
  euro: "\e919",
  export-excel: "\e91a",
  faq: "\e933",
  filters: "\e947",
  gallery-plus: "\e91b",
  gift: "\e95e",
  gift-box-open:"\e96e",
  glass: "\e91c",
  glasses: "\e962",
  global: "\e91d",
  graph: "\e965",
  grid: "\e91e",
  group: "\e91f",
  help: "\e920",
  home1: "\e921",
  home: "\e963",
  hourglass: "\e922",
  import: "\e95f",
  language-baloon: "\e968",
  list: "\e923",
  lista: "\e935",
  location: "\e924",
  mailchimp: "\e93b",
  marketing: "\e940",
  max-guests: "\e969",
  menu-horizontal: "\e925",
  menu-vertical: "\e926",
  minus: "\e970",
  min-guests: "\e96a",
  mktlist: "\e93a",
  notification: "\e927",
  online: "\e961",
  opened-gift: "\e96d",
  ordini: "\e93e",
  oval-outline: "\e929",
  oval: "\e928",
  paper-pencil: "\e957",
  plus: "\e92a",
  pos: "\e937",
  pre-order: "\e966",
  preview: "\e953",
  profile: "\e92b",
  restore: "\e952",
  sales: "\e936",
  search: "\e958",
  segments: "\e949",
  star-filled: "\e92c",
  star-outline: "\e92d",
  tag: "\e93f",
  target: "\e92e",
  terminal: "\e92f",
  threebottle: "\e943",
  timtim: "\e944",
  toast: "\e930",
  trash: "\e931",
  two-bottles: "\e964",
  two-people: "\e967",
  upload: "\e95a",
  warning: "\e95d",
  edit-pencil: "\e96f"
);
