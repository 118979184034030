@import "variables";

body {
  .mat-dialog-container {
    display: block;
    position: relative;
    padding: 0;
    border-radius: 10px;
    overflow-x: hidden;

    .btn-close {
      position: absolute;
      right: 0px;
      top: 0px;
      width: 40px;
      height: 40px;
      min-width: auto;
      padding: 0;

      span {
        font-weight: 600;
        transform: rotate(135deg);
        font-size: 30px;
        display: block;
      }
    }

    .mat-dialog-content {
      max-height: 96vh;
    }
  }
}

