form {
  .mat-error {
    position: absolute;
    background: color(base-2);
    padding: 15px 25px;
    color: color(base-3);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transform: translateY(calc(100% + 5px ));
    right: 0;
    bottom: 0;
    z-index: 1;
  }
}


